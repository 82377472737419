import React from 'react';
import { useTranslation } from 'react-i18next';

const Error = (props) => {
  const { t } = useTranslation();

  return (
    <section className="error-404-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="content clearfix">
              <div className="error-message">
                <h3>{t('error404.title')}</h3>
                <p>{t('error404.description')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
