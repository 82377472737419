import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const MetaTags = ({ description, title, image, url }) => {
  const { i18n } = useTranslation();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={`https://kesildigital.com${url}`} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={i18n.language === 'es' ? 'es_ES' : 'en_US'} />
    </Helmet>
  );
};

export default MetaTags;
