import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import ContactpageES from '../../components/Contactpage/ContactPage.es';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import MetaTags from '../../components/MetaTags/MetaTags';

const ContactPageES = () => {
  useLanguageChange({ currentLanguage: 'es', redirectTo: '/en/contact' });
  const metaTagsProps = {
    title: 'Contáctanos | Kesil Digital - Hablemos Sobre Tu Proyecto',
    description:
      '¿Estás interesado en nuestros servicios? Contacta a Kesil Digital para soluciones tecnológicas personalizadas en desarrollo web y móvil.',
    url: '/es/contacto',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 alwaysFixed />
      <ContactpageES />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPageES;
