import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import MetaTags from '../../components/MetaTags/MetaTags';

const DataPolicyPageES = () => {
  useLanguageChange({ currentLanguage: 'es', redirectTo: '/en/data-processing-policy' });
  const metaTagsProps = {
    title: 'Política de Tratamiento de Datos | Kesil Digital',
    description:
      'Conoce la política de tratamiento de datos de Kesil Digital. Garantizamos un manejo justo, transparente y seguro de tus datos personales.',
    url: '/en/data-processing-policy',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 alwaysFixed />
      <section className="wpo-contact-pg-section section-padding">
        <div className="container">
          <div className="row">
            <h2>Política de tratamiento de datos</h2>
            <p style={{ textAlign: 'justify' }}>
              Los datos recogidos en el formulario de contacto quedarán incorporados y serán tratados en el fichero denominado Usuarios Web bajo la
              responsabilidad de Kesil Digital C.A. RIF J-505319370 con el fin de poder prestar sus servicios, así como para mantenerle informado
              sobre cuestiones relativas a la actividad de la empresa. Le informamos que los datos que nos facilite serán tratados de forma leal,
              transparente, adecuada, pertinente, limitada, exacta y actualizada y no se comunicarán o cederán a terceros, exceptuando que esta
              comunicación sea necesaria o pueda suponer una mejora para la finalidad de los mismos. En el supuesto de que se produzcan cambios en
              alguno de los datos facilitados, con la finalidad de mantener nuestro fichero actualizado, le rogamos nos lo comunique por escrito. Le
              informamos de la posibilidad que tiene de ejercer los derechos de acceso, rectificación, supresión, oposición, limitación del
              tratamiento y portabilidad de sus datos de carácter personal mediante correo electrónico a contacto@kesildigital.com, acompañando copia
              de su cédula de identidad.
            </p>
          </div>
        </div>
        <section className="wpo-contact-map-section">
          <div className="wpo-contact-map"></div>
        </section>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default DataPolicyPageES;
