import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import ServicesES from '../../components/Services/Services.es';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import servicesBg from '../../images/services2-header.webp';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import MetaTags from '../../components/MetaTags/MetaTags';

const ServicePageES = () => {
  useLanguageChange({ currentLanguage: 'es', redirectTo: '/en/services' });
  const metaTagsProps = {
    title: 'Nuestros Servicios | Kesil Digital - Desarrollo de Aplicaciones Web y Móviles',
    description:
      'Descubre nuestros servicios: desarrollo de aplicaciones web y móviles a medida, consultoría tecnológica, integración de APIs, mantenimiento y soluciones de transformación digital.',
    image: servicesBg,
    url: '/es/servicios',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 />
      <PageTitle pageTitle={'¿Qué hacemos?'} pagesub={'Servicios'} bgImage={servicesBg} />
      <ServicesES />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePageES;
