import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import MetaTags from '../../components/MetaTags/MetaTags';
import { useTranslation } from 'react-i18next';

const BlogDetails = ({ Component, blog }) => {
  const { i18n } = useTranslation();
  const metaTagsProps = {
    title: blog.title[i18n.language],
    description: blog.subtitle[i18n.language],
    url: `/en/blog/${blog.url[i18n.language]}`,
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 />
      <Component blog={blog} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetails;
