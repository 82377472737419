import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import phoneCodes from '../../api/phoneCodes';
import useRequests from '../../hooks/useRequests';

const ContactFormEN = () => {
  const navigate = useNavigate();
  const requests = useRequests();
  const [state, setState] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
    acceptTerms: false,
    countryCode: '+58',
    openModal: false,
    loading: false,
    error: {},
  });

  const changeHandler = (e) => {
    const error = state.error;
    error[e.target.name] = '';

    if (e.target.name === 'phone') {
      const regex = /\+|^[0-9]*$/; // Only numbers
      if (regex.test(e.target.value)) {
        setState({
          ...state,
          [e.target.name]: e.target.value,
          error,
        });
      }

      return;
    }

    if (e.target.name === 'acceptTerms') {
      return setState({
        ...state,
        acceptTerms: !state.acceptTerms,
        error,
      });
    }

    setState({
      ...state,
      [e.target.name]: e.target.value,
      error,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const { fullName, email, phone, acceptTerms, error } = state;

    if (fullName === '') {
      error.fullName = 'Please enter your full name';
    }
    if (email === '') {
      error.email = 'Please enter your email';
    }
    if (phone === '') {
      error.phone = 'Please enter your phone number';
    }
    if (!acceptTerms) {
      error.acceptTerms = 'You must accept the policies';
    }
    if (error) {
      setState({
        ...state,
        error,
      });
    }

    if (error.fullName === '' && error.email === '' && error.phone === '' && !error.acceptTerms) {
      handleContactRequest();
      setState({
        ...state,
        loading: true,
        error: {},
      });
    }
  };

  const handleContactRequest = () => {
    const data = {
      Variables: {
        fullName: state.fullName,
        email: state.email,
        phone: state.countryCode + ' ' + state.phone,
        message: state.message,
      },
    };

    requests
      .post('/landing/contact', data)
      .then(() => {
        setState({
          fullName: '',
          email: '',
          phone: '',
          acceptTerms: false,
          message: '',
          loading: false,
        });
        return navigate('/en/thank-you');
      })
      .catch(() => {
        setState({
          loading: false,
        });
      });
  };

  const { fullName, email, phone, message, acceptTerms, error, loading } = state;

  return (
    <>
      <form onSubmit={submitHandler} className="form">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="form-field">
              <input value={fullName} onChange={changeHandler} type="text" name="fullName" placeholder="Full name" />
              <p>{error.fullName ? error.fullName : ''}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input onChange={changeHandler} value={email} type="email" name="email" placeholder="Email" />
              <p>{error.email ? error.email : ''}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <div className="col-12 d-flex align-items-center">
                <select
                  onChange={changeHandler}
                  name="countryCode"
                  value={state.countryCode}
                  style={{ width: '160px', marginRight: 5 }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  {phoneCodes.map(({ country, code }) => (
                    <option key={code} value={code}>
                      {`${country}`}
                    </option>
                  ))}
                </select>
                <input style={{ marginBottom: 0 }} onChange={changeHandler} value={phone} type="text" name="phone" placeholder="Phone / WhatsApp" />
              </div>
              <p>{error.phone ? error.phone : ''}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-field">
              <textarea value={message} onChange={changeHandler} name="message" placeholder="Message"></textarea>
            </div>
          </div>
          <div className="col-12">
            <div className="form-field">
              <div className="col-12 d-flex align-items-center">
                <input
                  style={{ width: '25px', cursor: 'pointer', marginLeft: 5 }}
                  onChange={changeHandler}
                  value={acceptTerms}
                  type="checkbox"
                  name="acceptTerms"
                />
                <p style={{ marginBottom: '0.5rem', marginLeft: '1rem', color: '#232f4b' }}>
                  I have read and accept the{' '}
                  <a href="/en/data-processing-policy" target="_blank" rel="noopener noreferrer">
                    data processing policies
                  </a>
                </p>
              </div>
              <p>{error.acceptTerms ? error.acceptTerms : ''}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-submit">
              <button type="submit" className="theme-btn">
                {loading ? 'Sending...' : 'Send'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactFormEN;
