import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

import blogHeader from '../../images/blog-header.webp';
import BlogListES from '../../components/BlogList/BlogList.es';
import MetaTags from '../../components/MetaTags/MetaTags';

const BlogPageES = () => {
  const metaTagsProps = {
    title: 'Blog | Kesil Digital - Novedades en Desarrollo Web y Móvil',
    description:
      'Mantente al día con las últimas tendencias en desarrollo web y móvil, ingeniería de software y transformación digital. Lee artículos de expertos en Kesil Digital.',
    image: blogHeader,
    url: '/es/blog',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 />
      <PageTitle pageTitle={'Artículos de interés'} pagesub={'Blog'} bgImage={blogHeader} />
      <BlogListES />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPageES;
