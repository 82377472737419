import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import AboutES from '../../components/about/About.es';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import aboutBg from '../../images/about-header.webp';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import MetaTags from '../../components/MetaTags/MetaTags';

const AboutPageES = () => {
  useLanguageChange({ currentLanguage: 'es', redirectTo: '/en/about-us' });
  const metaTagsProps = {
    title: 'Sobre Nosotros | Kesil Digital - Consultoría Tecnológica & Desarrollo de Software',
    description:
      'Kesil Digital ofrece consultoría tecnológica profesional y dedicada. Nos especializamos en desarrollo de aplicaciones web y móviles con un enfoque personalizado.',
    image: aboutBg,
    url: '/es/nosotros',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 />
      <PageTitle pageTitle={'Conócenos'} pagesub={'Nosotros'} bgImage={aboutBg} />
      <AboutES abClass={'wpo-about-section-s2'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPageES;
