import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar';

import blogs from '../../api/blogs';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../../hooks/useLanguageChange';
const BLOGS_PER_PAGE = 5;
const PAGES_QUANTITY = Math.ceil(blogs.length / BLOGS_PER_PAGE);

function paginate(array, page_size, page_number) {
  // page_number es el número de página que queremos (1-based)
  // page_size es la cantidad de elementos por página
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

const BlogListEN = (props) => {
  const [page, setPage] = useState(1);
  const { i18n } = useTranslation();
  const language = i18n.language;
  useLanguageChange({ currentLanguage: 'en', redirectTo: `/es/blogs` });

  const pageHandler = (direction, specificPage) => {
    if (specificPage) {
      setPage(specificPage);
      window.scrollTo(0, 400);
      return;
    }

    if (direction === 'previous' && page === 1) return;
    if (direction === 'next' && page === PAGES_QUANTITY) return;

    setPage(direction === 'previous' ? page - 1 : page + 1);
    window.scrollTo(0, 400);
  };

  return (
    <section className="wpo-blog-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              {paginate(blogs, BLOGS_PER_PAGE, page).map((blog, i) => {
                const route = `/${language}/blog/${blog.url?.[language]}`;
                return (
                  <div className="post format-standard-image">
                    <div className="entry-details mt-4">
                      <h3>
                        <Link to={route}>{blog.title[language]}</Link>
                      </h3>
                      <p>{blog.subtitle[language]}</p>
                      <Link to={route} className="read-more">
                        {language === 'es' ? 'LEER MÁS...' : 'READ MORE...'}
                      </Link>
                    </div>
                  </div>
                );
              })}

              <div className="pagination-wrapper pagination-wrapper-left">
                <ul className="pg-pagination">
                  <li>
                    <span aria-label="Anterior" onClick={() => pageHandler('previous')}>
                      <i className="fi ti-angle-left"></i>
                    </span>
                  </li>
                  {new Array(PAGES_QUANTITY).fill('').map((_, i) => (
                    <li className={page === i + 1 ? 'active' : ''}>
                      <span onClick={() => pageHandler(null, i + 1)}>{i + 1}</span>
                    </li>
                  ))}
                  <li>
                    <span aria-label="Siguiente" onClick={() => pageHandler('next')}>
                      <i className="fi ti-angle-right"></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default BlogListEN;
