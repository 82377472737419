/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useLanguageFromIp = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();

        const languageRegex = /^es(-|$)/;
        const language = languageRegex.test(data.languages) ? 'es' : 'en';
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    fetchLanguages();
  }, []);
};

export default useLanguageFromIp;
