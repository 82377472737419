import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import MetaTags from '../../components/MetaTags/MetaTags';

const DataPolicyPageEN = () => {
  useLanguageChange({ currentLanguage: 'en', redirectTo: '/es/politica-de-tratamiento-de-datos' });
  const metaTagsProps = {
    title: 'Data Processing Policy | Kesil Digital',
    description: "Learn about Kesil Digital's data processing policy. We ensure fair, transparent, and secure handling of your personal data.",
    url: '/en/data-processing-policy',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 alwaysFixed />
      <section className="wpo-contact-pg-section section-padding">
        <div className="container">
          <div className="row">
            <h2>Data processing policy</h2>
            <p style={{ textAlign: 'justify' }}>
              The data collected in the contact form will be incorporated and processed in the file called Web Users under the responsibility of Kesil
              Digital C.A. RIF J-505319370 in order to provide its services, as well as to keep you informed about issues related to the company's
              activity. We inform you that the data you provide will be treated fairly, transparently, adequately, pertinently, limitedly, accurately,
              and updated, and will not be communicated or transferred to third parties, except that this communication is necessary or may imply an
              improvement for the purpose of the same. In the event that changes occur in any of the data provided, in order to keep our file updated,
              we ask you to notify us in writing. We inform you of the possibility you have to exercise the rights of access, rectification, deletion,
              opposition, limitation of processing, and portability of your personal data by email to contacto@kesildigital.com, accompanied by a copy
              of your identity card.
            </p>
          </div>
        </div>
        <section className="wpo-contact-map-section">
          <div className="wpo-contact-map"></div>
        </section>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default DataPolicyPageEN;
