/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import AboutES from '../../components/about/About.es';

import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import MetaTags from '../../components/MetaTags/MetaTags';
import FeaturesEN from '../../components/Features/Features.en';

import HeroEN from '../../components/hero3/Hero.en';
import { useLanguageChange } from '../../hooks/useLanguageChange';

const HomePageEN = () => {
  useLanguageChange({ currentLanguage: 'en', redirectTo: '/es' });

  const metaTagsProps = {
    title: 'Kesil Digital - Technological Consulting | Web and Mobile Application Development',
    description:
      'Technological consulting and innovative software development for SMEs and startups. Kesil Digital offers personalized attention and effective solutions. Talk to us!',
    image: 'https://kesildigital.com/logo.png',
    url: '/en',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 />
      <HeroEN />
      <FeaturesEN featuresClass="wpo-features-section-s2" />
      <AboutES />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePageEN;
