import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import aboutBg from '../../images/about-header.webp';
import AboutEN from '../../components/about/About.en';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import MetaTags from '../../components/MetaTags/MetaTags';

const AboutPageEN = () => {
  useLanguageChange({ currentLanguage: 'en', redirectTo: '/es/nosotros' });
  const metaTagsProps = {
    title: 'About Us | Kesil Digital - Tech Consulting & Software Development',
    description:
      'Kesil Digital provides professional and dedicated tech consulting. We specialize in web and mobile app development with a tailored approach.',
    image: aboutBg,
    url: '/en/about-us',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 />
      <PageTitle pageTitle={'Get to know us'} pagesub={'About us'} bgImage={aboutBg} />
      <AboutEN abClass={'wpo-about-section-s2'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPageEN;
