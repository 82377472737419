import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import ContactpageEN from '../../components/Contactpage/ContactPage.en';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import MetaTags from '../../components/MetaTags/MetaTags';

const ContactPageEN = () => {
  useLanguageChange({ currentLanguage: 'en', redirectTo: '/es/contacto' });
  const metaTagsProps = {
    title: "Contact Us | Kesil Digital - Let's Talk About Your Project",
    description: 'Are you interested in our services? Contact Kesil Digital for personalized technology solutions in web and mobile development.',
    url: '/en/contact',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 alwaysFixed />
      <ContactpageEN />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPageEN;
