import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import servicesBg from '../../images/services2-header.webp';
import ServicesEN from '../../components/Services/Services.en';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import MetaTags from '../../components/MetaTags/MetaTags';

const ServicePageEN = () => {
  useLanguageChange({ currentLanguage: 'en', redirectTo: '/es/servicios' });
  const metaTagsProps = {
    title: 'Our Services | Kesil Digital - Web and Mobile Application Development',
    description:
      'Discover our services: custom web and mobile app development, technology consulting, API integration, maintenance, and digital transformation solutions.',
    image: servicesBg,
    url: '/en/services',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 />
      <PageTitle pageTitle={'What do we do?'} pagesub={'Services'} bgImage={servicesBg} />
      <ServicesEN />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePageEN;
