/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import Navbar2 from '../../components/Navbar2';
import HeroES from '../../components/hero3/Hero.es';
import FeaturesES from '../../components/Features/Features.es';
import AboutES from '../../components/about/About.es';

import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import MetaTags from '../../components/MetaTags/MetaTags';
import { useLanguageChange } from '../../hooks/useLanguageChange';

const HomePageES = () => {
  useLanguageChange({ currentLanguage: 'es', redirectTo: '/en' });

  const metaTagsProps = {
    title: 'Kesil Digital - Consultoría Tecnológica | Desarrollo de Aplicaciones Web y Móviles',
    description:
      'Consultoría tecnológica y desarrollo de software innovador para pymes y startups. Kesil Digital ofrece atención personalizada y soluciones eficaces. ¡Habla con nosotros!',
    image: 'https://kesildigital.com/logo.png',
    url: '/es',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 />
      <HeroES />
      <FeaturesES featuresClass="wpo-features-section-s2" />
      <AboutES />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePageES;
