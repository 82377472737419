import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import BlogListEN from '../../components/BlogList/BlogList.en';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

import blogHeader from '../../images/blog-header.webp';
import MetaTags from '../../components/MetaTags/MetaTags';

const BlogPageEN = () => {
  const metaTagsProps = {
    title: 'Blog | Kesil Digital - Insights on Web and Mobile Development',
    description:
      'Stay updated with the latest trends in web and mobile development, software engineering, and digital transformation. Read expert articles from Kesil Digital.',
    image: blogHeader,
    url: '/en/blog',
  };
  return (
    <Fragment>
      <MetaTags {...metaTagsProps} />
      <Navbar2 />
      <PageTitle pageTitle={'Articles of Interest'} pagesub={'Blog'} bgImage={blogHeader} />
      <BlogListEN />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPageEN;
